<template>
  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">
      <table class="w-full border border-gray-300">
        <thead>
          <tr>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">SL</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">User Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in notbilling" :key="index" class="bg-gray-100">
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">{{ index + 1 }}</td>
            <td class="pl-2 border-b border-l border-r border-gray-300">{{ list }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
import { ISP_LIST, NOT_BILLING } from "@core/services/api";
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      routerId: null,
      isp_id: JSON.parse(localStorage.getItem('userData')),
      name: null,
      price: null,
      total: 0,
      total_invoice: 0,
      notbilling: [],
      test: [],
      from_date: "",
      to_date: "",
      payment_status: null,
      payment_method: null,
    };
  },
  methods: {
    getnotbillingData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + NOT_BILLING + this.routerId)
        .then((res) => {
          this.notbilling = res.data.data.not_exist_in_billing;
          
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.notbilling = [];
          }
        });
    },
  },
  mounted() {
    this.routerId = this.$route.params.routerId; // Get the routerId from route params
    this.getnotbillingData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.error-message {
  color: red;
}
</style>
